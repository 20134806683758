<template>
  <div class="topbar-item">
    <div id="kt_quick_user_toggle" class="btn btn-icon btn-hover-transparent-white d-flex align-items-center btn-lg px-md-2 w-md-auto">
      <span class="text-white opacity-70 font-weight-bold font-size-base d-none d-md-inline mr-1">
        Hi,
      </span>
      <span class="text-white opacity-90 font-weight-bolder font-size-base d-none d-md-inline mr-4">
        {{ currentUser.nama_lengkap }}
      </span>
      <span class="symbol symbol-35">
        <span class="symbol-label text-white font-size-h5 font-weight-bold bg-white-o-30">
          {{ currentUser.nama_lengkap.substring(0, 1) }}
        </span>
      </span>
    </div>

    <div id="kt_quick_user" ref="kt_quick_user" class="offcanvas offcanvas-right p-10">
      <!--begin::Header-->
      <div class="offcanvas-header d-flex align-items-center justify-content-between pb-5">
        <h3 class="font-weight-bold m-0">
          User Profile
          <small class="text-muted font-size-sm ml-2"></small>
        </h3>
        <a href="#" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_user_close">
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar class="offcanvas-content pr-5 mr-n5 scroll" style="max-height: 90vh; position: relative;">
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
          <div class="symbol symbol-100 mr-5">
            <!-- <img class="symbol-label" :src="picture" alt="" /> -->
            <!-- <i class="symbol-badge bg-success"></i> -->
          </div>
          <div class="d-flex flex-column">
            <a href="#" class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary">
              {{ currentUser.nama_lengkap }}
            </a>
            <div class="text-muted mt-1">{{ currentUser.username }}</div>
            <div class="text-muted mt-1 mb-2">Aktif : {{ masa_aktif }}</div>
            <div class="text-muted mt-1 mb-2">Modal 1Inch : {{ getLimit1Inch[0] }} - {{ getLimit1Inch[1] }}</div>
            <div class="text-muted mt-1 mb-2">Modal Matcha : {{ getLimitMatcha[0] }} - {{ getLimitMatcha[1] }}</div>
            <button class="btn btn-light-primary btn-bold" @click="onLogout">
              Sign out
            </button>
          </div>
        </div>
        <!--end::Header-->
      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import { LOGOUT } from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import { mapGetters } from "vuex";
import CryptoService from "@/core/services/crypto.service";

export default {
  name: "KTQuickUser",
  data() {
    return {};
  },
  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
  },
  methods: {
    onLogout() {
      this.$store.dispatch(LOGOUT).then(() => this.$router.push({ name: "login" }));
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },
  },
  computed: {
    ...mapGetters(["currentUser", "isAuthenticated", "loginToken", "getLimit1Inch", "getLimitMatcha"]),
    picture() {
      return process.env.BASE_URL + "media/users/300_21.jpg";
    },
    masa_aktif() {
      let tzoffset = new Date().getTimezoneOffset() * 60 * 1000; //offset in milliseconds
      if (this.loginToken.length == 32) {
        return new Date(Date.now() - tzoffset).toISOString().split("T")[0];
      } else {
        try {
          let payload = JSON.parse(CryptoService.decrypt(this.loginToken));
          return new Date(payload.expired - tzoffset).toLocaleString("id-ID");
        } catch (e) {
          return "Indefinite";
        }
      }
    },
  },
};
</script>
